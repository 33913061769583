import React from "react";

import "../../css/main.css";
import "../../css/fonts.css";
import "../../css/reset.css";

// import MediaPage from "../../components/Media";
import MediaGallery from "../../components/Media/MediaGallery";
import Seo from "../../components/SeoHead";
import Layout from "../../components/Blog/Layout"
import data from "../../data";

const Wallpapers = () => {
	return (
		<>
			<Seo />
			<Layout>
				<MediaGallery
					title="Wallpapers"
					prefix="wallpapers"
					content={data.mediaPage.wallpapers}
				/>
			</Layout>
		</>
	);
};


export default Wallpapers
